import type { GatsbyBrowser } from 'gatsby';
import 'element-closest';
import smoothscroll from 'smoothscroll-polyfill';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);

  const observer = new MutationObserver(() => {
    ScrollTrigger.refresh();
  });

  observer.observe(window.document, { childList: true, subtree: true });
}

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  smoothscroll.polyfill();
};
